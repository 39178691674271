import PropTypes from 'prop-types';
import Link from 'next/link';
import ManagersMore from './ManagersMore';
import Badge from '../Badge/Badge';
import Status from '../Badge/Status';
import Avatar from '../Avatar/Avatar';
import { AVATAR_SMALL_SIZE } from '../../constants/values';
import styles from './sass/ManagersWrap.module.scss';

const ManagersWrap = ({
  managers, maxToShow, width, height, onClick
}) => {
  const isDisplayNumber = managers.length >= maxToShow;
  const moreDataPlaceholder = 1;
  let truncatedItems = [...managers];

  if (isDisplayNumber) {
    truncatedItems = truncatedItems.slice(0, maxToShow - moreDataPlaceholder);
  }

  return (
    <div className={styles.wrap}>
      {truncatedItems.map((item, index) => (
        <div className={styles.manager} key={[item.user.id, index].join()}>
          <Link href={`/employees/${item.user.id}`} title={item.user.name} onClick={onClick}>
            <Badge statuses={item.status && <Status status={item.status} />}>
              <Avatar
                image={item.user.photo48_link}
                alt={item.user.name}
                width={width}
                height={height}
              />
            </Badge>
          </Link>
        </div>
      ))}
      {isDisplayNumber && (
        <ManagersMore
          remainingItems={managers.slice(maxToShow - moreDataPlaceholder, managers.length)}
          numberOfRemainingItems={managers.length - maxToShow + moreDataPlaceholder}
          size={AVATAR_SMALL_SIZE}
          onClick={onClick}
        />
      )}
    </div>
  );
};

ManagersWrap.propTypes = {
  managers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  maxToShow: PropTypes.number.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
};

ManagersWrap.defaultProps = {
  width: 32,
  height: 32,
  onClick: () => {}
};

export default ManagersWrap;
